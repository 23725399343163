<script lang="ts">
  import { translationKeys } from '$lib/translations/translationConstants';
  import { _ } from '$lib/translations/i18n';
  import { isSmallScreen } from '$lib/helpers/window';
  import Footer from '$lib/components/mainPage/footer/Footer.svelte';

  let smallScreen = isSmallScreen();
</script>

<section
  id="Discover"
  class="flex flex-col w-full items-center justify-center bg-[url('$lib/assets/img/footer.svg')] bg-cover mt-24"
>
  <div class="flex flex-col items-center justify-center mt-[4.5rem] w-full">
    <p
      class="{smallScreen ? 'body-l' : 'text-xl'} font-bold text-[#E9643A] tracking-[0.0625em] mb-5"
    >
      {$_(translationKeys.welcomePageDiscover)}
    </p>
    <h2
      class="{smallScreen
        ? 'text-[26px]'
        : 'text-[2.625rem]'} font-bold text-white text-center mb-10"
    >
      {$_(translationKeys.welcomePageCustomizedLicence)}
    </h2>
    <div class="flex justify-center items-center mb-[4.5rem] {smallScreen ? 'mt-[10%]' : ''}">
      <button
        class="flex items-center justify-center w-[8.5rem] h-[3.125rem] border-2 border-white rounded-lg bg-[#FFFFFF0D]"
      >
        <a
          class="text-base font-bold tracking-[0.0015em] text-white"
          href="https://www.servalit.com"
          target="_blank"
          rel="noopener noreferrer">{$_(translationKeys.contactUs)}</a
        >
      </button>
    </div>
  </div>
  <div class="flex items-center justify-center w-full mb-14">
    <Footer />
  </div>
</section>
