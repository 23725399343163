<script lang="ts">
  import { translationKeys } from '$lib/translations/translationConstants';
  import { _ } from '$lib/translations/i18n';
  import scoreboardIcon from '$lib/assets/icons/icon-scoreboard.svg';
  import skillStarIcon from '$lib/assets/icons/icon-skill-star.svg';
  import analysisIcon from '$lib/assets/icons/icon-analysis.svg';
  import whistleIcon from '$lib/assets/icons/icon-whistle.svg';
  import Feature from './Feature.svelte';
  import { isSmallScreen } from '$lib/helpers/window';

  let smallScreen = isSmallScreen();
</script>

<section id="HowItWorks" class="flex flex-row w-full my-10">
  <div class="w-full">
    <h4
      class="text-center mb-3 text-[#133841] {smallScreen
        ? 'text-[28px]'
        : 'text-[2.625rem]'} font-bold"
    >
      {$_(translationKeys.welcomePageHowItWorks)}
    </h4>
    <div
      class="flex flex-col w-[80%] bg-[url('$lib/assets/img/features-arrow.svg')] my-0 mx-auto bg-cover"
      class:features-container-mobile={smallScreen}
    >
      <div
        class="flex flex-row {smallScreen ? 'mb-0' : 'mb-24'} justify-between"
        class:features-column={smallScreen}
      >
        <Feature
          icon={whistleIcon}
          altText={'Invite teammates'}
          titleKey={translationKeys.welcomePageInviteTeammatesTitle}
          textKey={translationKeys.welcomePageInviteTeammatesText}
        />
        <Feature
          icon={scoreboardIcon}
          altText={'Post game'}
          titleKey={translationKeys.welcomePagePostGameTitle}
          textKey={translationKeys.welcomePagePostGameText}
        />
      </div>
      <div class="flex flex-row justify-between" class:features-column={smallScreen}>
        <Feature
          icon={skillStarIcon}
          altText={'Player skill level'}
          titleKey={translationKeys.welcomePageAddPlayerTitle}
          textKey={translationKeys.welcomePageAddPlayerText}
        />
        <Feature
          icon={analysisIcon}
          altText={'Game analysis'}
          titleKey={translationKeys.welcomePageGameHistoryTitle}
          textKey={translationKeys.welcomePageGameHistoryText}
        />
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .features-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url('$lib/assets/img/features-arrow-mobile.svg');
    background-size: cover;
  }
  .features-column {
    display: flex;
    flex-direction: column;
  }
</style>
