<script lang="ts">
  import type { Faq } from './faq';
  import { translationKeys } from '$lib/translations/translationConstants';
  import { _ } from '$lib/translations/i18n';
  import Question from './Question.svelte';
  import { isSmallScreen } from '$lib/helpers/window';
  import { Accordion } from 'flowbite-svelte';
  import FaqImage from '$lib/assets/img/faq-image.webp';

  $: questions = [
    {
      question: $_(translationKeys.welcomePageWhatIsBalanseroQuestion),
      answer: $_(translationKeys.welcomePageWhatIsBalanseroAnswer)
    },
    {
      question: $_(translationKeys.welcomePageHowToCreateANewMatchQuestion),
      answer: $_(translationKeys.welcomePageHowToCreateANewMatchAnswer)
    },
    {
      question: $_(translationKeys.welcomePageCanIInviteFriendsToJoinQuestion),
      answer: $_(translationKeys.welcomePageCanIInviteFriendsToJoinAnswer)
    },
    {
      question: $_(translationKeys.welcomePageHowDoesTheTeamBalancingFeatureWorkQuestion),
      answer: $_(translationKeys.welcomePageHowDoesTheTeamBalancingFeatureWorkAnswer)
    },
    {
      question: $_(translationKeys.welcomePageHowDoesTheVotingSystemWorkQuestion),
      answer: $_(translationKeys.welcomePageHowDoesTheVotingSystemWorkWorkAnswer)
    },
    {
      question: $_(translationKeys.welcomePageCanIScheduleWeeklyMatches),
      answer: $_(translationKeys.welcomePageCanIScheduleWeeklyMatchesAnswer)
    },
    {
      question: $_(translationKeys.welcomePageCanIDeleteMyAccountQuestion),
      answer: $_(translationKeys.welcomePageCanIDeleteMyAccountAnswer)
    }
  ] as Faq[];

  let smallScreen = isSmallScreen();
</script>

<section id="FAQ" class="flex flex-row mt-14 mx-auto w-full">
  <div class="w-full flex flex-col">
    <div class="flex items-center justify-center">
      <h2 class="{smallScreen ? '' : 'text-[2.625rem]'} font-bold text-[#133841]">
        {$_(translationKeys.welcomePageFAQ)}
      </h2>
    </div>
    <div class="flex flex-row mt-[4.5rem] justify-center" class:faq-content-mobile={smallScreen}>
      <!-- Here will go faq image -->
      <div
        class="flex items-center justify-center {smallScreen
          ? 'w-[90vw] h-[30vh] mt-5'
          : 'w-[35vw] h-[50vh]'}"
      >
        <img
          src={FaqImage}
          alt="faq"
          class="faq-img rounded-[1.625rem] w-full h-[95%]"
          class:faq-image-mobile={smallScreen}
        />
      </div>
      <div
        class="flex flex-col justify-center items-center  {smallScreen
          ? ' min-w-[100%] max-w-[100%]'
          : ' min-w-[60%] max-w-[60%] pl-10'}"
        class:questions-mobile={smallScreen}
      >
        <Accordion multiple flush class="min-w-[95%] max-w-[95%]">
          {#each questions as question}
            <Question question={question.question} answer={question.answer} />
          {/each}
        </Accordion>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .faq-img {
    background-color: rgba(45, 144, 168, 0.2);
  }
  .faq-content-mobile {
    flex-direction: column-reverse;
    align-items: center;
  }
  .faq-image-mobile {
    margin-top: 10%;
  }
  .questions-mobile {
    width: 95%;
    margin-top: 5%;
  }
</style>
