<script lang="ts">
  import { goto } from '$app/navigation';
  import { onMount } from 'svelte';
  import { _ } from '$lib/translations/i18n';
  import Features from '$lib/components/mainPage/featuresSection/Features.svelte';
  import Intro from '$lib/components/mainPage/introSection/Intro.svelte';
  import BalanseroHeader from '$lib/components/mainPage/header/Header.svelte';
  import Faq from '$lib/components/mainPage/faqSection/Faq.svelte';
  import Discover from '$lib/components/mainPage/discoverSection/Discover.svelte';
  import { isInStandaloneMode, isSmallScreen } from '$lib/helpers/window';
  import { Spinner } from 'flowbite-svelte';
  import { translationKeys } from '$lib/translations/translationConstants';

  let mainContent: HTMLElement;
  let smallScreen: boolean | undefined = undefined;
  let show = false;

  const gotoSignUpPage = async () => {
    await goto('/auth/signUp');
  };

  const gotoLoginPage = async () => {
    await goto('/auth/login');
  };

  onMount(async () => {
    var header = document.getElementById('header');
    var HEADER_HIDDEN = 'hidden';
    var HEADER_TRANSPARENT = 'transparent';
    var HEADER_OFFSET_TOP = 120;

    var currScrollPos = 0;
    var lastKnownScrollPos = 0;
    var isHidden = false;
    var isTransparent = true;
    var ticking = false;

    function handleHeader(scrollPosition: number) {
      isHidden = scrollPosition > lastKnownScrollPos && lastKnownScrollPos > HEADER_OFFSET_TOP;
      isTransparent = HEADER_OFFSET_TOP >= scrollPosition;

      lastKnownScrollPos = scrollPosition;

      if (header) {
        header.classList.toggle(HEADER_HIDDEN, isHidden);
        header.classList.toggle(HEADER_TRANSPARENT, isTransparent);
      }
    }

    function onScroll() {
      currScrollPos = window.scrollY;

      if (!ticking) {
        requestAnimationFrame(function () {
          handleHeader(currScrollPos);
          ticking = false;
        });
      }

      ticking = true;
    }

    window.addEventListener('load', onScroll, false);
    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', onScroll, false);
    smallScreen = isSmallScreen();

    if (smallScreen && isInStandaloneMode()) {
      await goto('/portal');
    }

    show = true;
  });
</script>

<svelte:head>
  <title>{$_(translationKeys.welcomePageBalanseroTitle)}</title>
  <meta name="description" content={$_(translationKeys.welcomePageWhatIsBalanseroAnswer)} />
  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.3/umd/popper.min.js"></script>

  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" />
  <link
    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap"
    rel="stylesheet"
  />

  <script type="application/ld+json">
    {
      "@context": "http://schema.org",
      "@type": "SoftwareApplication",
      "name": "Balansero",
      "author": "Servalit",
      "applicationCategory": "SportsApplication",
      "description": "Balansero is a web app that helps groups of people organize sport events, such as football matches. The app allows users to create new matches, balance teams, and rate teammates after the match.",
      "featureList": [
        "Organize football matches",
        "Balance teams for matches",
        "Rate teammates after matches"
      ],
      "operatingSystem": "Web"
    }
  </script>
</svelte:head>

<main class="flex flex-col bg-white max-w-[100vw]" bind:this={mainContent}>
  {#if smallScreen !== undefined && show}
    <BalanseroHeader onButtonClick={gotoLoginPage} {mainContent} />
    <Intro onButtonClick={gotoSignUpPage} />
    <Features />
    <Faq />
    <Discover />
  {:else}
    <div
      class="h-[100vh] w-full flex flex-col items-center justify-center bg:balansero-dark dark:bg-balansero-dark"
    >
      <Spinner size="14" color="purple" />
    </div>
  {/if}
</main>
