<script lang="ts">
  export let icon: string;
  export let altText: string;
  export let titleKey: string;
  export let textKey: string;

  import { isSmallScreen } from '$lib/helpers/window';
  import { _ } from '$lib/translations/i18n';

  let smallScreen = isSmallScreen();
</script>

<div class="flex flex-row w-[45%]" class:container-mobile={smallScreen}>
  <div class="flex items-center justify-center w-[11rem]">
    <div
      class="icon-container min-h-[11rem] min-w-[11rem] rounded-2xl flex items-center justify-center"
    >
      <img src={icon} alt={altText} class="w-full h-full icon" />
    </div>
  </div>
  <div class="pt-5 pl-5 flex flex-col justify-center items-start {smallScreen ? 'w-[60%]' : ''}">
    <h3 class="{smallScreen ? 'text-[20px]' : 'text-[2rem]'} font-bold text-[#133741]">
      {$_(titleKey)}
    </h3>
    <span class="{smallScreen ? 'subtitle-s' : 'text-xl'} font-normal text-[#21272A]">
      {$_(textKey)}
    </span>
  </div>
</div>

<style lang="scss">
  .icon-container {
    box-shadow: 0px 2px 4px rgba(223, 237, 246, 0.4), 0px 40px 70px rgba(180, 212, 235, 0.04),
      0px 16px 36px rgba(180, 212, 235, 0.04), 0px 8px 24px rgba(180, 212, 235, 0.08),
      0px 6px 8px rgba(180, 212, 235, 0.12);
    @media (max-width: 1000px) {
      min-width: 10rem;
      min-height: 10rem;
    }
  }
  .container-mobile {
    width: 100%;
    align-items: center;
  }
</style>
