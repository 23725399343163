<script lang="ts">
  export let onButtonClick: any;

  import { isSmallScreen } from '$lib/helpers/window';
  import { _ } from '$lib/translations/i18n';
  import { translationKeys } from '$lib/translations/translationConstants';
  import BackgroundPhone from '$lib/assets/img/background-phone.webp';
  import { ChevronRight } from 'flowbite-svelte';

  let smallScreen = isSmallScreen();
</script>

<section
  class="flex flex-row bg-[url('$lib/assets/img/hero.webp')] bg-cover w-full"
  class:section-intro-mobile={smallScreen}
>
  <div
    class={!smallScreen
      ? 'intro-content-title flex flex-col items-start justify-center pb-40 pt-28 w-1/2 pl-44'
      : 'intro-section-content-mobile'}
  >
    <h1 class="{smallScreen ? '' : 'text-[3.25rem]'} font-bold text-white max-w-[31.4rem]">
      {$_(translationKeys.welcomePageTitle)}
    </h1>
    <h4 class="mt-8 text-white {smallScreen ? '' : 'text-xl font-normal'} max-w-[36.2rem]">
      {$_(translationKeys.welcomePageSubtitle)}
    </h4>
    <div class="mt-[3.25rem]">
      <button
        on:click={onButtonClick}
        class="orange-button flex items-center justify-center h-[3.125rem] w-44 rounded-lg"
      >
        <span class="text-base font-bold text-white flex flex-row items-center"
          >{$_(translationKeys.welcomePageGetStarted)}
          <ChevronRight size="20" variation="solid" /></span
        >
      </button>
    </div>
  </div>
  <div class="intro-img flex items-center justify-end w-1/2 pt-32">
    <img src={BackgroundPhone} alt="Phone" width="100%" />
  </div>
</section>

<style lang="scss">
  :global(.section-intro-mobile) {
    color: white;
    background-image: url('$lib/assets/img/hero-mobile.webp');
    background-size: cover;
  }
  .intro-content-title {
    @media (max-width: 1200px) {
      padding-bottom: 8rem;
      padding-left: 6rem;
    }
  }
  .intro-img {
    @media (max-width: 1200px) {
      padding-top: 6rem;
    }
  }
  :global(.intro-section-content-mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    padding: 0 5%;
    margin-top: 80%;
    margin-bottom: 20%;
    text-align: center;
  }
</style>
