<script lang="ts">
  export let question: string;
  export let answer: string;

  import { MinusSolid, PlusSolid } from 'svelte-awesome-icons';
  import { AccordionItem } from 'flowbite-svelte';
  import { isSmallScreen } from '$lib/helpers/window';

  let smallScreen = isSmallScreen();
</script>

<AccordionItem class="flex w-full"
  ><h4 slot="header" class="{smallScreen ? '' : ' text-2xl'} font-bold text-midnight-black">
    {question}
  </h4>
  <p
    class="{smallScreen ? 'body-m' : 'text-lg font-medium'} !text-midnight-black tracking-[0.005em]"
  >
    {@html answer}
  </p>
  <div slot="arrowup">
    <MinusSolid color="red" />
  </div>
  <div slot="arrowdown">
    <PlusSolid color="black" />
  </div>
</AccordionItem>
